import React, { useEffect, useRef,useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  styled,
} from "@mui/material";

const AnimatedImage = styled("img")(({ theme }) => ({
  width: "200px",
  height: "auto",
  marginRight: "60px",
  opacity: 0,
  transform: "translateX(-100%)",
  transition: "transform 1s ease-out, opacity 1s ease-out",
}));

const FloatingImage = styled("img")({
  position: "absolute",
  zIndex: 1,
  width: "100px",
  height: "100px",
});

const Portfolio = () => {
  const [isInView, setIsInView] = useState(false);
  const imageRef = useRef(null);

  // Define arrays for your images and links
  const topImageCards1 = [
    {
      heading: "IKD Active",
      image: "image/IKD.jpg",
      link: "https://www.figma.com/proto/wRV7KAJhYAU1JxVYpAPZPH/TDF-Portfolio?page-id=0%3A1&node-id=1-1074&viewport=2487%2C1082%2C0.16&t=JwystZEuKRCa93C6-1&scaling=scale-down&content-scaling=fixed",
      title: "Until Next Time Logo 5",
    },
    {
      heading: "Been & Kin",
      image: "image/bee&keen.png",
      link: "https://www.figma.com/proto/wRV7KAJhYAU1JxVYpAPZPH/TDF-Portfolio?page-id=0%3A1&node-id=1-732&viewport=2487%2C1082%2C0.16&t=JwystZEuKRCa93C6-1&scaling=scale-down&content-scaling=fixed",
      title: "Until Next Time Logo 6",
    },
    {
      heading: "Pudofast",
      image: "image/pudofast.jpg",
      link: "https://www.figma.com/proto/wRV7KAJhYAU1JxVYpAPZPH/TDF-Portfolio?page-id=0%3A1&node-id=1-858&viewport=2487%2C1082%2C0.16&t=JwystZEuKRCa93C6-1&scaling=scale-down&content-scaling=fixed",
      title: "Until Next Time Logo 7",
    },
  ];

  const topImageCards = [
    {
      heading: "Eta Solution",
      image: "image/EtaSolution.jpg",
      link:"https://www.figma.com/proto/wRV7KAJhYAU1JxVYpAPZPH/TDF-Portfolio?page-id=0%3A1&node-id=1-413&viewport=2487%2C1082%2C0.16&t=JwystZEuKRCa93C6-1&scaling=scale-down&content-scaling=fixed",
      title: "Until Next Time Logo 1",
    },
    {
      heading: "Influen Z",
      image: "image/influenZ.jpg",
      link: "https://www.figma.com/proto/wRV7KAJhYAU1JxVYpAPZPH/TDF-Portfolio?page-id=0%3A1&node-id=1-228&viewport=2487%2C1082%2C0.16&t=JwystZEuKRCa93C6-1&scaling=scale-down&content-scaling=fixed",
      title: "Until Next Time Logo 2",
    },
    {
      heading: "Avoura",
      image: "image/Avoura.jpg",
      link: "https://www.figma.com/proto/wRV7KAJhYAU1JxVYpAPZPH/TDF-Portfolio?page-id=0%3A1&node-id=1-612&viewport=2487%2C1082%2C0.16&t=JwystZEuKRCa93C6-1&scaling=scale-down&content-scaling=fixed",
      title: "Until Next Time Logo 3",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          } else {
            setIsInView(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <Box
    id="C"
      sx={{
        backgroundColor: "#0e0e10",
        padding: { xs: 2, sm: 4, md: 6, lg: 8 }, // Reduced padding
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center", // Changed to center for better alignment
          marginRight: { xs: "250px", sm: "300px", md: "350px" },
          width: "100%",
          marginTop: { xs: 2, sm: 3, md: 4 }, 
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        {/* <AnimatedImage
          src="image/cub.png"
          alt="Portfolio Icon"
          ref={imageRef}
          width={{ xs: "150px", sm: "200px", md: "300px" }}
        /> */}
        <Box
        ref={imageRef}
        component="img"
        src="image/cub.png"
        alt="Cube"
        sx={{
          position: 'absolute',
          top: '150px', 
          left: '190px', 
          width: { xs: '200px', sm: '400px', md: '300px' }, 
          height: 'auto',
          zIndex: 10,
          transform: isInView ? 'scale(1)' : 'scale(0.5)',
          opacity: isInView ? 1 : 0,
          transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
        }}
      />
        <Typography
          sx={{
            color: "#f08080",
            fontWeight: 600,
            fontSize: { xs: 40, sm: 60, md: 85 },
            marginTop: { xs: 1, sm: 0 }, // Added small margin for mobile
            marginLeft: { xs: 16, sm: 30,md:60 }, // Added margin between image and text
            fontFamily: "Syne, sans-serif",
          }}
        >
          Portfolio
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap", // Make cards wrap on small screens
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          gap: { xs: 3, sm: 5, md: 10 }, // Responsive gap between cards
          marginTop: { xs: 5, md: 10 }, // Responsive top margin
        }}
      >
        {topImageCards.map((card, index) => (
          <Card
            key={index}
            onClick={() => window.open(card.link, "_blank")}
            sx={{
              fontFamily: "Syne, sans-serif",
              borderRadius: "30px",
              flex: "1 1 auto",
              maxWidth: { xs: "400px", sm: "390px", md: "530px" }, // Responsive width
              height: { xs: "640px", md: "820px" }, // Responsive height
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <img
              src={card.image}
              alt={`Card ${index + 1}`}
              style={{
                width: "100%",
                height: "auto", // Responsive height for images
                objectFit: "cover",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
              }}
            />
            <CardContent
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                textAlign: "left",
                padding: 1,
              }}
            >
              {/* <Typography sx={{ fontSize: { xs: 30, md: 50  ,fontFamily: "Syne, sans-serif", fontWeight:'bold', } }}>
            {card.title}
          </Typography> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Syne, sans-serif",
                  fontWeight: "bold",
                  fontSize: "50px",
                }}
              >
                {card.heading}
              </Typography>
              <Divider sx={{ marginY: 2 }} />
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontFamily: "Syne, sans-serif",
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "40px",
                }}
              >
                View Project
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
      
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap", // Enable wrapping for small screens
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          gap: { xs: 4, sm: 7, md: 10 }, // Responsive gap
          marginTop: { xs: "20px",sm:"40px", md: "50px" }, // Responsive margin top
        }}
      >
        
        {topImageCards1.map((card, index) => (
          <Card
            key={index}
            onClick={() => window.open(card.link, "_blank")}
            sx={{
              borderRadius: "30px",
              flex: "1 1 auto",
              maxWidth: { xs: "400px", sm: "390px", md: "530px" }, // Responsive width
              height: { xs: "640px", md: "900px" }, // Responsive height
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <img
              src={card.image}
              alt={`Card ${index + 1}`}
              style={{
                width: "100%",
                height: "auto", // Responsive height
                objectFit: "cover",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
              }}
            />
            <CardContent
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                textAlign: "left",
                padding: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Syne, sans-serif",
                  fontWeight: "bold",
                  fontSize: "50px",
                }}
              >
                {card.heading}
              </Typography>
              <Divider sx={{ marginY: 2 }} />
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontFamily: "Syne, sans-serif",
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "100px",
                }}
              >
               View Project
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Floating Images */}
      {/* <FloatingImage
        src="image/p4.png"
        style={{
          top: "57%",
          right: "0px",
          width: "100%",
          maxWidth: "500px",
          height: "auto",
          transform: "translateY(-50%)",
        }}
        alt="Right Side Image"
      /> */}
      <Box
        ref={imageRef}
        component="img"
        src="image/p4.png"
        alt="p4"
        sx={{
          position: 'absolute',
          right: '80px', 
          top: "42%",
          width: { xs: '200px', sm: '400px', md: '490px' }, 
          height: 'auto',
          zIndex: 10,
          transform: isInView ? 'scale(1)' : 'scale(0.5)',
          opacity: isInView ? 1 : 0,
          transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
        }}
      />

<Box
        ref={imageRef}
        component="img"
        src="image/p3.png"
        alt="Cube"
        sx={{
          position: 'absolute',
          bottom: '50px', 
          left: '100px', 
          width: { xs: '200px', sm: '400px', md: '300px' }, 
          height: 'auto',
          zIndex: 10,
          transform: isInView ? 'scale(1)' : 'scale(0.5)',
          opacity: isInView ? 1 : 0,
          transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
        }}
      />
    </Box>
  );
};

export default Portfolio;
