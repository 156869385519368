import React, { useEffect, useRef ,useState} from 'react';
import { Grid, Typography, Box, Paper, Accordion, AccordionSummary, AccordionDetails, IconButton, } from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';

// Custom styled components
const CustomContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#FF5630',
  padding: '70px',
  borderTopColor: 'white',
  borderBottomColor: 'white',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

const ServiceBox = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FF8666',
  borderRadius: '30px',
  fontFamily: 'Syne, sans-serif',
  marginLeft: '100px',
  boxShadow: 'none',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
    height: '100%',
  },
}));

const CustomText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontFamily: 'Syne, sans-serif',
  [theme.breakpoints.down('sm')]: {
    '&.MuiTypography-h3': {
      fontSize: '1.2rem',
    },
    '&.MuiTypography-h4': {
      fontSize: '1rem',
    },
  },
}));

// Services array remains the same
// const services = [
//   {
//     title: 'Brand Creation',
//     description: 'Building unique, impactful brands that resonate.',
//   },
//   {
//     title: 'Web Design',
//     description: 'Crafting stunning, functional websites.',
//   },
//   {
//     title: 'Creative Strategy & Storytelling',
//     description: 'Developing compelling narratives for your brand.',
//   },
//   {
//     title: 'Performance Creative',
//     description: 'High-quality videos and motion graphics.',
//   },
//   {
//     title: 'Pitches & Presentations',
//     description: 'Creating persuasive, engaging presentations.',
//   },
// ];
const faqData = [
  { title: "Why not just hire a full-time designer?", 
    description: "Great question! Hiring a senior-level designer full-time can cost over $100,000 annually, plus benefits—and that’s if you can find one who’s available. Even then, you might not always have enough work to keep them busy, leaving you paying for idle time. With our flexible monthly plan, you only pay for what you use. Need a break? Pause your subscription anytime and resume when new work arises." },
    
  { title: "Is there a limit to how many requests I can make?",
    description: "Nope! You can add as many design requests to your queue as you’d like. We’ll tackle them one at a time, ensuring each gets the attention it deserves."},
    
  { title: "How quickly will my designs be delivered?",
    description: "Most designs are completed within 48 hours or less. However, for more intricate projects, it might take a bit longer to ensure the best quality."},

  { title: "What can I expect in 48 hours?", 
    description: "Standard requests—like a brand concept, landing page, or social media templates—are typically delivered within 48 hours. For larger projects, deliverables will roll out every 24-48 hours until the full request is completed."},

  { title: "Who will be working on my designs?",
    description: "Surprise! The Designer Factory operates as a solo design studio, meaning you’ll work directly with the founder. For specialized tasks, like animations or custom illustrations, we collaborate with expert partner designers."},

  { title: "How are larger projects handled?",
    description: "Big projects, like full website or app designs, are broken into manageable parts. You’ll receive a steady stream of progress, with new deliverables every 24-48 hours, until the entire project is finished."},

  { title: "What tools do you design with?",
    description: "Our primary tool is Figma, the industry standard for modern design."},
    
  { title: "How do I submit design requests?",
    description: "We use Trello to streamline requests, but you’re free to share your ideas however works best—whether that’s through Google Docs, wireframes, or a quick Loom video. If it can be linked or uploaded to Trello, it works for us." },

  { title: "What if I’m not happy with the design?",
    description: "No problem! We’ll refine and revise until you’re completely satisfied." },

  { title: "Are there any types of designs you don’t offer?",
    description: "Yes, there are a few things we don’t handle, including: 3D modeling, Animated graphics (GIFs, etc.), Document design (like medical forms), Complex packaging, Extensive print design (magazines, books, etc.), Adobe InDesign-specific project"},

  { title: "Can I sign up for just one request?", 
    description: "Absolutely! If you only have one design need, you can complete it and then pause your subscription. Your remaining time won’t go to waste—you can resume anytime when new requests arise. Due to the high-quality nature of our work, we don’t offer refunds. Rest assured, we’ll do everything to ensure you’re thrilled with the service."}

];
function FaqList() {
  const firstImageRef = useRef(null);
  const secondImageRef = useRef(null);
  const [expanded, setExpanded] = useState(-1); 

  const handleToggle = (index) => {
    setExpanded(prevExpanded => (prevExpanded === index ? -1 : index));
  };

  useEffect(() => {
    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.transform = 'scale(1.2)';
          entry.target.style.opacity = 1;
        } else {
          entry.target.style.transform = 'scale(0.8)';
          entry.target.style.opacity = 0;
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.8,
    });

    const firstImage = firstImageRef.current;
    const secondImage = secondImageRef.current;
    
    if (firstImage) observer.observe(firstImage);
    if (secondImage) observer.observe(secondImage);

    return () => {
      if (firstImage) observer.unobserve(firstImage);
      if (secondImage) observer.unobserve(secondImage);
    };
  }, []);

  return (
    <CustomContainer id='faqs'>
  <img
    ref={firstImageRef}
    src="image/cir.png"
    alt="Decorative"
    style={{
      position: 'absolute',
      right: '30px',
      top: '50px',
      width: '20%',
      maxWidth: '350px',
      height: 'auto',
      transform: 'scale(0.8)',
      opacity: 0,
      transition: 'transform 1s ease-out, opacity 1s ease-out',
      zIndex: 2,
      // Add responsive sizing for mobile
      '@media (max-width: 600px)': {
        width: '60%', // Adjust size on mobile
        top: '20px',  // Adjust top position on mobile
        right: '20px', // Adjust right position
      },
    }}
  />

  <img
    ref={secondImageRef}
    src="image/15.png"
    alt="Decorative"
    style={{
      position: 'absolute',
      left: '50px',
      top: '700px',
      width: '20%',
      maxWidth: '120px',
      height: 'auto',
      transform: 'scale(0.8)',
      opacity: 0,
      transition: 'transform 1s ease-out, opacity 1s ease-out',
      zIndex: 2,
      // Add responsive sizing for mobile
      '@media (max-width: 600px)': {
        width: '20%', // Adjust size on mobile
        left: '10px',  // Adjust left position on mobile
        top: '400px',  // Adjust top position
      },
    }}
  />

  <Typography
    variant="h2"
    align="left"
    gutterBottom
    sx={{
      marginLeft: { xs: '20px', sm: '50px' ,md:"10px"},
      color: 'white',
      fontFamily: 'Syne, sans-serif',
      fontSize: { xs: '2.rem', sm: '3.75rem' },
      marginTop: { xs: '20px', sm: '30px' }, // Added margin for mobile
      p:3
    }}
  >
    Frequently Asked Questions
  </Typography>

  {/* <Grid container spacing={{ xs: 2, sm: 10 }} sx={{ padding: { xs: '10px', sm: '70px' } }}> */}
    {/* {services.map((service, index) => (
      <Grid
        item
        xs={6} // This makes it 2 columns on mobile
        sm={6} // Maintains 2 columns on tablet
        md={6} // Maintains original layout on desktop
        key={index}
      >
        <ServiceBox
          sx={{
            padding: { xs: '15px', sm: '50px' },
            minHeight: { xs: '120px', sm: 'auto' },
          }}
        >
          <CustomText variant="h3" gutterBottom>
            {service.title}
          </CustomText>
          <CustomText variant="h4">
            {service.description}
          </CustomText>
        </ServiceBox>
        {index === 3 && (
          <Box
            mt={1}
            sx={{
              position: 'absolute',
              marginTop: { xs: '50px', sm: '150px' },
              marginLeft: { xs: '10px', sm: '120px' },
              color: 'white',
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1.2rem', sm: '2.5rem' },
              }}
            >
              And many more.............
            </Typography>
          </Box>
        )}
      </Grid>
    ))} */}

{faqData.map((item, index) => (
      <Accordion
      key={index}
      expanded={expanded === index}
      onChange={() => handleToggle(index)}
      sx={{
        backgroundColor: '#FCA392',
        padding: "10px",
        margin: "28px",
        borderRadius: '15px',
        border: '2px solid none',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              marginRight:"20px",
              width: '70px',
              height: '70px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            {expanded === index ? (
              <Remove sx={{ color: '#f35444', fontSize: 48 }} />
            ) : (
              <AddIcon sx={{ color: '#f35444', fontSize: 48 }} />
            )}
          </IconButton>
        }
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography variant="h4" sx={{ p: 2, pb: 0, m: 2, fontFamily: 'Syne, sans-serif',fontSize:{xs:'28px',sm:"34px" ,md:'35px'} }}>
          {item.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ p:2,m:2, fontFamily: 'Syne, sans-serif', fontSize: "27px" }}>
          {item.description}
        </Typography>
      </AccordionDetails>
    </Accordion>
      ))}
  {/* </Grid> */}
</CustomContainer>

  );
}

export default FaqList;