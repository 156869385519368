import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";

const PriceText = styled(Typography)({
  color: "#ff4500",
  fontSize: "30px",
  fontWeight: "bold",
  textAlign: "center",
  fontFamily: "Syne, sans-serif",
});

const ListItem = styled(Typography)({
  display: "flex",
  alignItems: "center",
  marginBottom: "12px",
  fontSize: "20px",
  fontFamily: "Syne, sans-serif",
});

const SwitchLabel = styled(Typography)({
  color: "#ff4500",
  fontSize: "18px",
  marginTop: "5px",
  fontFamily: "Syne, sans-serif",
});

const SaveBox = styled(Box)({
  backgroundColor: "#fff",
  color: "#000",
  padding: "15px 25px",
  borderRadius: "15px",
  display: "inline-block",
  marginTop: "20px",
  fontWeight: "bold",
  fontSize: "20px",
  fontFamily: "Syne, sans-serif",
});

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: "#1a1a1a",
  justifyContent: "center",
  alignItems: "center",
  // minHeight: "100vh",
  padding: {
    xs: "20px",
    sm: "50px",
    md: "100px",
  },
  fontFamily: "Syne, sans-serif",
}));

// Update CustomCard and CustomBox to be responsive
const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: "#000",
  borderRadius: "15px",
  marginTop: "100px",
  marginBottom: "100px",
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(1),
  },
  height: "auto", // Change from fixed height to auto
  padding: {
    xs: "20px",
    sm: "30px",
    md: "50px",
  },
  width: {
    xs: "90%", // Use percentage for better mobile layout
    sm: "600px",
    md: "800px",
  },
  boxShadow: "0 8px 30px rgba(0, 0, 0, 0.3)",
}));

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#dbdbdb",
  color: "#000",
  borderRadius: "15px",
  padding: {
    xs: "20px",
    sm: "40px",
    md: "60px",
  },
  width: {
    xs: "90%", // Use percentage for better mobile layout
    sm: "600px",
    md: "1000px",
  },
  // height :{
  //   xs :"500px",
  //   md:"auto"
  // },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(1),
  },
  boxShadow: "0 8px 30px rgba(0, 0, 0, 0.15)",
  fontFamily: "Syne, sans-serif",
}));

// Update CustomButton to be more responsive
const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#000",
  color: "#fff",
  padding: {
    xs: "10px 20px",
    sm: "15px 30px",
    md: "20px 38px",
  },
  marginTop: "40px",
  marginLeft: "auto", // Changed from fixed marginLeft
  marginRight: "auto", // Added marginRight
  display: "block", // This helps with centering
  borderRadius: "25px",
  fontWeight: "bold",
  textTransform: "none",
  fontSize: {
    xs: "20px",
    sm: "30px",
    md: "40px",
  },
  fontFamily: "Syne, sans-serif",
  width: "fit-content", // Changed from 100% to fit-content
}));

function CostCalculator() {
  const statesData = {
    Alabama: { hiringCost: 6533, tdfCost: 499, savings: 6034 },
    Alaska: { hiringCost: 7685, tdfCost: 499, savings: 7186 },
    Arizona: { hiringCost: 6832, tdfCost: 499, savings: 6333 },
    Arkansas: { hiringCost: 6384, tdfCost: 499, savings: 5885 },
    California: { hiringCost: 8642, tdfCost: 499, savings: 8143 },
    Colorado: { hiringCost: 7025, tdfCost: 499, savings: 6526 },
    Connecticut: { hiringCost: 7489, tdfCost: 499, savings: 6990 },
    Delaware: { hiringCost: 7275, tdfCost: 499, savings: 6776 },
    DistrictOfColumbia: { hiringCost: 7692, tdfCost: 499, savings: 7193 },
    Florida: { hiringCost: 6573, tdfCost: 499, savings: 6074 },
    Georgia: { hiringCost: 6792, tdfCost: 499, savings: 6293 },
    Hawaii: { hiringCost: 7209, tdfCost: 499, savings: 6710 },
    Idaho: { hiringCost: 6434, tdfCost: 499, savings: 5935 },
    Illinois: { hiringCost: 7293, tdfCost: 499, savings: 6794 },
    Indiana: { hiringCost: 6730, tdfCost: 499, savings: 6231 },
    Iowa: { hiringCost: 6677, tdfCost: 499, savings: 6178 },
    Kansas: { hiringCost: 6776, tdfCost: 499, savings: 6277 },
    Kentucky: { hiringCost: 6562, tdfCost: 499, savings: 6063 },
    Louisiana: { hiringCost: 6500, tdfCost: 499, savings: 6001 },
    Maine: { hiringCost: 6567, tdfCost: 499, savings: 6068 },
    Maryland: { hiringCost: 4892, tdfCost: 499, savings: 4393 },
    Massachussets: { hiringCost: 7784, tdfCost: 499, savings: 7285 },
    Michigan: { hiringCost: 6996, tdfCost: 499, savings: 6497 },
    Minnesota: { hiringCost: 7369, tdfCost: 499, savings: 6870 },
    Mississippi: { hiringCost: 6187, tdfCost: 499, savings: 5688 },
    Missouri: { hiringCost: 6779, tdfCost: 499, savings: 6280 },
    Montana: { hiringCost: 6657, tdfCost: 499, savings: 6158 },
    Nebraska: { hiringCost: 6539, tdfCost: 499, savings: 6040 },
    Nevada: { hiringCost: 7030, tdfCost: 499, savings: 6531 },
    NewHampshire: { hiringCost: 7095, tdfCost: 499, savings: 6596 },
    NewJersey: { hiringCost: 7403, tdfCost: 499, savings: 6904 },
    NewMexico: { hiringCost: 6507, tdfCost: 499, savings: 6008 },
    NewYork: { hiringCost: 8127, tdfCost: 499, savings: 7628 },
    NorthCarolina: { hiringCost: 6751, tdfCost: 499, savings: 6252 },
    NorthDakota: { hiringCost: 6754, tdfCost: 499, savings: 6255 },
    Ohio: { hiringCost: 6784, tdfCost: 499, savings: 6285 },
    Oklahoma: { hiringCost: 6318, tdfCost: 499, savings: 5819 },
    Oregon: { hiringCost: 7204, tdfCost: 499, savings: 6705 },
    Pennsylvania: { hiringCost: 7338, tdfCost: 499, savings: 6839 },
    RhodeIsland: { hiringCost: 7278, tdfCost: 499, savings: 6779 },
    SouthCarolina: { hiringCost: 6409, tdfCost: 499, savings: 5910 },
    SouthDakota: { hiringCost: 6213, tdfCost: 499, savings: 5714 },
    Tennessee: { hiringCost: 6447, tdfCost: 499, savings: 5948 },
    Texas: { hiringCost: 6807, tdfCost: 499, savings: 6308 },
    Utah: { hiringCost: 6642, tdfCost: 499, savings: 6143 },
    Vermont: { hiringCost: 6764, tdfCost: 499, savings: 6265 },
    Virginia: { hiringCost: 6826, tdfCost: 499, savings: 6327 },
    Washington: { hiringCost: 7628, tdfCost: 499, savings: 7129 },
    WestVirginia: { hiringCost: 6308, tdfCost: 499, savings: 5809 },
    Wisconsin: { hiringCost: 6776, tdfCost: 499, savings: 6277 },
    Wyoming: { hiringCost: 6364, tdfCost: 499, savings: 5865 },
  };

  const [selectedState, setSelectedState] = useState("Alabama");
  const [costs, setCosts] = useState(statesData[selectedState]);

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setSelectedState(newState);
    setCosts(statesData[newState]);
  };

  return (
    <Container id="pricing">
    <Box sx={{ textAlign: "left" ,paddingLeft:"75px" , minHeight:"40px"}}>
      <Typography
        variant="h5"
        sx={{
          color: '#fff',
          paddingLeft:"250px",
          paddingTop:{xs:"60px",md:"80px"},
          fontWeight: 'bold',
          fontSize: { xs: 40, sm: 60, md: 88 },
          marginLeft:"50px",
          fontFamily: 'Syne, sans-serif',
         
        }}
      >
        Pricing
      </Typography>
    </Box>
  
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: { xs: 'row', md: 'row' },
        alignItems: 'center',
      }}
    >
      <CustomCard>
        <CardContent>
          <Typography
            variant="h2"
            fontWeight="bold"
            textAlign="center"
            sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "4rem" } }}
          >
            Christmas Sale
          </Typography>
          <PriceText
            variant="h2"
            color="#ff4500"
            textAlign="center"
            sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "4rem" } }}
          >
            $499/month
          </PriceText>
          <Box sx={{
            margin:{xs:"15px"}
          }}>
          <img
            src="image/desktop_icon.png"
            style={{
              display: "block",
              margin: "0 auto",
              width:{xs:"10px",md:"50px"},
              height:{xs:"10px",md:"100px"},
              fontSize: { xs: 40, sm: 60, md: 100 },
            }}
          />
          </Box>
         
          <Box mt={3} sx={{ ml: { xs: 2, sm: 5, md: 20 }, mr: { xs: 2, sm: 5, md: 20 } }}>
            {[
              "Sr. Level Designer",
              "Unlimited Requests",
              "One Request at a Time",
              "48-Hour Delivery Avg.",
              "Unlimited Revisions",
              "No Commitment",
              "Cancel Anytime",
            ].map((text, index) => (
              <ListItem key={index} sx={{ fontSize: { xs: "0.9rem", sm: "1.5rem", md: "2.5rem" } }}>
                <CheckIcon
                  sx={{
                    marginRight: "10px",
                    fontSize: { xs: "1.2rem", sm: "1.8rem", md: "2.8rem" },
                  }}
                />
                {text}
              </ListItem>
            ))}
          </Box>
          <CustomButton
            variant="contained"
            sx={{
              fontSize: { xs: "1rem", sm: "1.5rem", md: "2.2rem" },
              padding: { xs: "15px 30px", sm: "17px 36px", md: "19px 38px" },
              marginTop: { xs: "15px", sm: "20px", md: "30px" },
              display: 'block',
              mx: 'auto'
            }}
          >
            <a
              href="https://buy.stripe.com/7sI03vdKd4zQ8X628l"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
              Start Today
            </a>
          </CustomButton>
        </CardContent>
      </CustomCard>
  
      <CustomBox>
        <CardContent
          sx={{
            width: { xs: '90%', sm: '95%', md: '900px' },
            height: { xs: 'auto', sm: '600px', md: '700px' },
            padding: { xs: '20px', sm: '30px', md: '50px' },
            margin: '0 auto'
          }}
        >
          <FormControl fullWidth margin="normal">
            <Typography
              sx={{
                fontSize: { xs: "1.5rem", sm: "2.25rem", md: "4.25rem" },
                fontWeight: "bold",
                fontFamily: "Syne, sans-serif"
              }}
            >
              Choose Your State
            </Typography>
            <Select
              value={selectedState}
              onChange={handleStateChange}
              sx={{
                backgroundColor: "#fff",
                fontSize: { xs: "0.9rem", sm: "1.2rem", md: "2rem" },
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              {Object.keys(statesData).map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant="caption"
              display="block"
              sx={{
                marginTop: "10px",
                fontSize: { xs: "1rem", sm: "1.5rem", md: "2.5rem" },
                fontWeight: "bold",
              }}
            >
              Avg. salary for a Sr-Level designer
            </Typography>
          </FormControl>
  
          <Box mt={{ xs: 5, sm: 10, md: 15 }}>
            <Typography
              sx={{
                fontSize: { xs: "1rem", sm: "1.4rem", md: "2rem" },
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Hiring Costs/mo: <span>${costs.hiringCost}</span>
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", sm: "1.4rem", md: "2rem" },
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              TDF Cost/mo: <span>${costs.tdfCost}</span>
            </Typography>
            <SaveBox
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "1rem", sm: "1.4rem", md: "2rem" },
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Saving Each Month: <span>${costs.savings}</span>
            </SaveBox>
          </Box>
        </CardContent>
      </CustomBox>
    </Container>
    helo
  </Container>
     
  
  );
}

export default CostCalculator;
